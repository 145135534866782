<template>
  <component
    :is="$sbLinkFix(blok.link) ? NuxtLink:'div'"
    v-editable="blok"
    :to="$sbLinkFix(blok.link)"
    :target="$sbLinkTarget(blok.link)"
    class="PromoBlock block w-full group"
    :class="desktopWidth('full')"
  >
    <div
      :style="{backgroundColor : blok.backgroundColor.color}"
      class="relative overflow-hidden"
      :class="[ratioClass]"
    >
      <div
        v-if="blok.asset && blok.asset.filename"
        class="w-full h-full absolute top-0 left-0
        transition-transform duration-500"
        :class="[backgroundScale]"
      >
        <video
          v-if="checkIfVideoFile(blok.asset.filename)"
          width="w-full"
          height="h-full"
          class="w-full h-full object-cover"
          loop
          muted
          autoplay
          playsinline
        >
          <source :src="blok.asset.filename" type="video/mp4">
        </video>
        <nuxt-img
          v-else-if="blok.asset && blok.asset.filename"
          preset="standard"
          loading="lazy"
          :sizes="`sm:100vw desk:${imageSize}`"
          class="h-full w-full object-cover"
          :src="blok.asset.filename"
          :alt="blok.asset.alt"
        />
      </div>

      <div
        v-if="blok.desktopAsset && blok.desktopAsset.filename"
        class="hidden desk:block w-full h-full absolute top-0 left-0
        transition-transform duration-500"
        :class="[backgroundScale]"
      >
        <video
          v-if="checkIfVideoFile(blok.desktopAsset.filename)"
          width="w-full"
          height="h-full"
          class="w-full h-full object-cover"
          loop
          muted
          autoplay
          playsinline
        >
          <source :src="blok.desktopAsset.filename" type="video/mp4">
        </video>
        <nuxt-img
          v-else-if="blok.desktopAsset && blok.desktopAsset.filename"
          preset="standard"
          loading="lazy"
          :sizes="`sm:100vw desk:${imageSize}`"
          class="h-full w-full object-cover"
          :src="blok.desktopAsset.filename"
          :alt="blok.desktopAsset.alt"
        />
      </div>

      <div
        class="relative w-full h-full p-24 transition-transform duration-500"
        :class="[highlightScale, contentPadding]"
      >
        <div v-if="blok.highlightImage && blok.highlightImage.filename" class="w-full h-full">
          <video
            v-if="checkIfVideoFile(blok.highlightImage.filename)"
            width="w-full"
            height="h-full"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.highlightImage.filename" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.highlightImage && blok.highlightImage.filename"
            preset="standard"
            loading="lazy"
            :sizes="`sm:100vw desk:${imageSize}`"
            class="h-full w-full object-cover"
            :src="blok.highlightImage.filename"
            :alt="blok.highlightImage.alt"
          />
        </div>
        <div
          v-if="blok.desktopHighlightImage && blok.desktopHighlightImage.filename"
          class="hidden desk:block relative h-full w-full transition-transform duration-500"
          :class="[highlightScale]"
        >
          <video
            v-if="checkIfVideoFile(blok.desktopHighlightImage.filename)"
            width="w-full"
            height="h-full"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.desktopHighlightImage.filename" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.desktopHighlightImage && blok.desktopHighlightImage.filename"
            preset="standard"
            loading="lazy"
            :sizes="`sm:100vw desk:${imageSize}`"
            class="h-full w-full object-cover"
            :src="blok.desktopHighlightImage.filename"
            :alt="blok.desktopHighlightImage.alt"
          />
        </div>
      </div>
      <div
        class="absolute top-12 left-12 w-64 h-64 -rotate-12"
        :class="[topSplashSize, topSplashPosition, { 'spin': blok.spinSplash, }]"
      >
        <div
          v-if="blok.splashTopText || blok.splashText"
          :style="{backgroundColor : blok.splashBackgroundColor.color}"
          class="h-full w-full rounded-full flex flex-col items-center justify-center gap-2 text-center"
          :class="
            { 'text-darkest': isLight(blok.splashBackgroundColor.color),
              'text-lightest': !isLight(blok.splashBackgroundColor.color) }"
        >
          <div
            v-if="blok.splashTopText"
            class="mb-2 -mt-2 type-tagline-xxs"
            :class="[discountTextSize]"
          >
            {{ blok.splashTopText }}
          </div>
          <div
            v-if="blok.splashText"
            class="type-headline-xs leading-single"
            :class="[discountNumberSize]"
          >
            {{ blok.splashText }}
          </div>
        </div>
        <div v-else class="w-full h-full rounded-full overflow-hidden">
          <nuxt-img
            v-if="blok.splashImage && blok.splashImage.filename"
            preset="standard"
            loading="lazy"
            :sizes="`sm:100vw desk:${imageSize}`"
            :src="blok.splashImage.filename"
            :alt="blok.splashImage.alt"
          />
        </div>
      </div>
      <div
        class="absolute bottom-12 right-12 w-64 h-64"
        :class="[topSplashSize, bottomSplashPosition, { 'spin': blok.spinDiscountSplash, }]"
      >
        <div
          v-if="blok.splashDiscountTopText || blok.splashDiscountText"
          :style="{backgroundColor : blok.splashDiscountBackgroundColor.color}"
          class="h-full w-full rounded-full flex flex-col items-center justify-center gap-2 text-center"
          :class="
            { 'text-darkest': isLight(blok.splashDiscountBackgroundColor.color),
              'text-lightest': !isLight(blok.splashDiscountBackgroundColor.color) }"
        >
          <div
            v-if="blok.splashDiscountTopText"
            class="mb-2 -mt-2 type-tagline-xxs"
            :class="[discountTextSize]"
          >
            {{ blok.splashDiscountTopText }}
          </div>
          <div
            v-if="blok.splashDiscountText"
            class="type-headline-xs leading-single"
            :class="[discountNumberSize]"
          >
            {{ blok.splashDiscountText }}
          </div>
        </div>
        <div v-else class="w-full h-full rounded-full overflow-hidden">
          <nuxt-img
            v-if="blok.splashDiscountImage && blok.splashDiscountImage.filename"
            preset="standard"
            loading="lazy"
            :sizes="`sm:100vw desk:${imageSize}`"
            :class="[bottomSplashSize]"
            :src="blok.splashDiscountImage.filename"
            :alt="blok.splashDiscountImage.alt"
          />
        </div>
      </div>
    </div>
    <div
      v-if="blok.preamble || blok.title || blok.tagline || blok.buttonText"
      class="flex flex-col gap-8 px-12 pt-16"
      :class="[
        contentMargin, {
          'items-center text-center': !blok.textAlign || blok.textAlign === 'center',
          'items-start': blok.textAlign === 'left',
          'items-end': blok.textAlign === 'right',
        }]"
    >
      <div v-if="blok.preamble" class="type-tagline dotted-border">
        {{ blok.preamble }}
      </div>
      <div v-if="blok.title" :class="[titleSize]">
        {{ blok.title }}
      </div>
      <div v-if="blok.tagline" :class="[taglineSize]">
        {{ blok.tagline }}
      </div>
      <div v-if="blok.buttonText" class="btn btn--arrow mt-8">
        {{ blok.buttonText }}
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { PromoBlock } from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { checkIfVideoFile } from '~/util/imageUtil';
import { AspectRatioKeys } from '~/constants/aspectRatioKeys';
import { isLight } from '~/util/colorUtil';

const NuxtLink = resolveComponent('NuxtLink');

type Props = {
  blok: PromoBlock;
  size?: 'full' | 'half' | 'third' | 'fourth'; // size if in a promowrapper
  insideGrid?: boolean;
  placement: number;
  aspectRatioMobile?: AspectRatioKeys;
  aspectRatioDesktop?: AspectRatioKeys;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'full',
  aspectRatioMobile: AspectRatioKeys.Square,
  aspectRatioDesktop: AspectRatioKeys.Landscape,
});
const { desktopWidth } = useDesktopWidth(props);

const titleSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-headline-lg desk:type-headline-3xl';
    case 'half':
      return 'type-headline-lg desk:type-headline-2xl';
    case 'third':
      return 'type-headline-lg desk:type-headline-xl';
    case 'fourth':
      return 'type-headline-lg';
  }
});
const taglineSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-sm desk:type-base';
    case 'half':
      return 'type-sm desk:type-base';
    case 'third':
      return 'type-sm desk:type-base';
    case 'fourth':
      return 'type-sm';
  }
});

const topSplashPosition = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:top-24 desk:left-24';
  }
});

const bottomSplashPosition = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:bottom-24 desk:right-24';
  }
});

const topSplashSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:w-112 desk:h-112';
    case 'half':
      return 'desk:w-96 desk:h-96';
    case 'third':
      return 'desk:w-80 desk:h-80';
  }});

const bottomSplashSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:bottom-56 desk:right-56 desk:w-112 desk:h-112';
    case 'half':
      return 'desk:bottom-32 desk:right-32 desk:w-96 desk:h-96';
    case 'third':
      return 'desk:bottom-20 desk:right-20 desk:w-80 desk:h-80';
  }});

const discountTextSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:-mt-8 desk:type-tagline';
    case 'half':
      return 'desk:-mt-8 desk:type-tagline';
  }
});

const discountNumberSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:type-headline-xl';
    case 'half':
      return 'desk:type-headline-lg';
    case 'third':
      return 'desk:type-headline-base';
    case 'fourth':
      return 'desk:type-headline-base';
  }
});

const contentPadding = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:p-56';
  }
});

const contentMargin = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:pt-20';
    case 'half':
      return 'desk:pt-20';
  }});

const imageSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return '100vw';
    case 'half':
      return '50vw';
    case 'third':
      return '33vw';
    case 'fourth':
      return '25vw';
    default:
      return '1260px';
  }
});

const backgroundScale = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'group-hover:scale-105';
    case 'half':
      return 'group-hover:scale-105';
    case 'third':
      return 'group-hover:scale-110';
    case 'fourth':
      return 'group-hover:scale-110';
  }
});

const highlightScale = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'group-hover:scale-95';
    case 'half':
      return 'group-hover:scale-95';
    case 'third':
      return 'group-hover:scale-90';
    case 'fourth':
      return 'group-hover:scale-90';
  }
});

const ratioClass = computed(()=> {
  let output = '';
  if (props.aspectRatioMobile !== '') {
    output += `mobOnly:${props.aspectRatioMobile} `;
  }
  if (props.aspectRatioDesktop !== '') {
    output += `desk:${props.aspectRatioDesktop}`;
  }
  return output;
});

</script>

<style scoped lang="postcss">
.spin {
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.pulse {
  animation-name: pulse;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
</style>
